<template>
  <div class="news-section-container" >
    <div class="news-section-container__header">
      <div class="news-section-container__header--left">
        <button @click="toggleSelectedSection('Trending News')" class="btn" :class="{'active': selectedSection === 'Trending News'}">News</button>
        <button @click="toggleSelectedSection('Insights')" class="btn btn-two" :class="{'active': selectedSection === 'Insights'}">Research</button>
      </div>
      <div class="news-section-container__header--right">
        <MyPopperWrapper arrow class="popper-container">
          <button class="request-btn" @click="openPopper">
            <i class="icon icon-add_plus"></i>
            Request a source
          </button>
          <template #content v-if="visibleRequestSourceModal">
            <request-news-source :requestType="requestedNewsSourceType"></request-news-source>
          </template>
        </MyPopperWrapper>
      </div>
    </div>
    <div class="news-section-container__body">
      <article-grid-view :activeSection="selectedSection"> </article-grid-view>
    </div>
    <!-- <div v-else class="news-section-container__body" :class="containerClasses+'__body'">
      <insight-view :activeSection="activeSection[0].module_name"> </insight-view>
    </div> -->
    <div class="news-section-container__footer">
     <the-pagination
        v-if="selectedSection === 'Trending News'"
        @change-page="searchNewsArticles" 
        :pageNumber="pageNumber"
        :totalPages="totalPages" 
        :results="articlesLength"
        @move-first-page="goToFirstPage"
        @move-last-page="goToLastPage">
      </the-pagination>
      <the-pagination
      v-else
      @change-page="searchInsightsArticles" 
      :pageNumber="pageNumberResearchArticles"
      :totalPages="totalPagesResearchArticles" 
      :results="researchArticleLength"
      @move-first-page="goToFirstPageInsight"
      @move-last-page="goToLastPageInsight">
    </the-pagination>
    </div>
  </div>
</template>

<script>
import ArticleGridView from "./ArticleGridView.vue";
// import RequestNewsSource from '../BaseComponents/RequestNewsSource.vue';
import MyPopperWrapper from "vue3-popper";
import ThePagination from "../BaseComponents/CustomPagination.vue";
import { mapActions } from "vuex";
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ArticleGridView,
    RequestNewsSource: defineAsyncComponent(() => import('@/components/BaseComponents/RequestNewsSource.vue')),
    MyPopperWrapper,
    ThePagination
  },
  props: {
    activeSection: Array
  },
  data() {
    return {
      visibleRequestSourceModal: false,
      openiscussionCollapse: "",
      selectedSection:"Trending News"
    };
  },
  computed: {
    containerClasses() {
      const value = this.activeSection.find((module) => {
        return module.module_name === 'Trending News';
      });
      const isFirstOrder = value.order === 0;
      const dynamicClass = isFirstOrder ? 'left-pdb-section' : 'right-pdb-section';
      return dynamicClass;
    },
    userId(){
      return this.$store.getters["auth/getUserId"];
    },
    results() {
      return this.selectedSection === 'Trending News' ? 
      {
        value: this.$store.getters["discover/getArticleTotalResults"].value,
        relation: this.$store.getters["discover/getArticleTotalResults"].relation,
      }
      :
      {
        value: this.$store.getters["discover/getInsightsTotalResults"].value,
        relation: this.$store.getters["discover/getInsightsTotalResults"].relation,
      }
    },
    pageNumber() {
      return this.$store.getters["discover/getCurrenPageNumber"];
    },
    totalPages() {
      if (this.results.value) {
        return Math.ceil(this.results.value / 18);
      }
      return 0;
    },
    articlesLength() {
      return this.$store.getters["discover/getTotalArticles"];
    },
    pageOffset: {
      get() {
        return this.$store.getters["discover/getArticlesPageOffset"];
      },
      set(val) {
        this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", val)
      }
    },
    pageNumberResearchArticles() {
      return this.$store.getters["discover/getInisghtsCurrenPageNumber"];
    },
    totalPagesResearchArticles() {
      if (this.results.value) {
        return Math.ceil(this.results.value / 18);
      }
      return 0;
    },
    researchArticleLength() {
      return this.$store.getters["discover/getInsightsTotalArticles"];
    },
    pageOffsetResearchArticles: {
      get() {
        return this.$store.getters["discover/getInsightsPageOffset"];
      },
      set(val) {
        this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", val)
      }
    },
    requestedNewsSourceType(){
      return this.selectedSection === 'Trending News' ? 'news source' : 'research institute'
    }
  },
  async mounted(){
    this.emitter.on('closeNewsSourceModal', ()=>{
      this.visibleRequestSourceModal = false
    })
    const payload = {
          user_id: this.userId,
        }
    await this.$store.dispatch("article/fetchSaveArticleFolder",payload);
  },
  methods: {
    ...mapActions({
      setSelectedModule: 'discover/setSelectedModule'
    }),
    openPopper() {
      this.visibleRequestSourceModal = true;
      this.emitter.emit('clearFields');
    },
    async searchNewsArticles(page) {
      this.pageOffset = page === 'next' ? this.pageOffset + 18 : Math.max(0, this.pageOffset - 18);
      await this.$store.dispatch("discover/searchArticles");
    },
    async goToFirstPage(){
      this.pageOffset = 0;
      await this.$store.dispatch("discover/searchArticles");
    },
    async goToLastPage(){
      this.pageOffset = this.results.value - 2;
      await this.$store.dispatch("discover/searchArticles");
    },
    toggleSelectedSection(sectionName){
      this.selectedSection = sectionName
      this.setSelectedModule(sectionName)
    },
    async searchInsightsArticles(page) {
      this.pageOffsetResearchArticles = page === 'next' ? this.pageOffsetResearchArticles + 18 : Math.max(0, this.pageOffsetResearchArticles - 18);
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToFirstPageInsight(){
      this.pageOffsetResearchArticles = 0;
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToLastPageInsight(){
      this.pageOffsetResearchArticles = this.results.value - 2;
      await this.$store.dispatch("discover/searchInsights");
    },
  },
};
</script>

<style lang="scss">
.news-section-container {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  height: calc(100vh - 16.4rem);
  overflow: hidden;
  position: relative;
  margin: 0 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-dark-grey-5;
    padding: 1.8rem 2.6rem;
    @include respond(laptop-large) {
      padding: 1.8rem 2.6rem !important;
    }
    &--left {
      display: flex;
      align-items: center;
      .btn {
        line-height: 2rem;
        font-family: $font-primary;
        background-color: $color-white;
        font-size: 1.6rem;
        padding: 0 2.5rem;
        height: 4rem;
        border-radius: 100px !important;
        border: 1px solid #cdcdcd;
        outline: none;
        cursor: pointer;
        color: #434343 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        position: relative;
        z-index: 2;
        @media (max-width: 400px) {
          font-size: 1.6rem;
          padding: 0 3rem;
          height: 4.5rem;
        }
      }
      .btn-two {
        margin-left: -6rem;
        margin-right: 0;
        position: relative;
        z-index: 1;
        padding-left: 7rem;
      }
      .active {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white !important;
        font-family: $font-primary-medium;
      }
    }

    &--right {
      display: flex;
      align-items: center;

      .inline-block {
        border: 0 !important;
        margin: 0 !important;
        display: block;

        @include respond(phone-x-small) {
          display: none;
        }
      }
      .popper-container {
        position: relative;
        .popper {
          right: 0 !important;
          top: 100% !important;
          transform: translate(-47%, 2%) !important;
          cursor: default;
          @media only screen and (max-width: 768px) {
            right: 50% !important;
            transform: translate(-34%, 2%) !important;
          }
        }
      }
    }
  }

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2.5rem 2.6rem;
    height: calc(100% - 8rem);
    @include respond(laptop-large) {
      padding: 2.2rem 2.6rem !important;
    }

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    background-color: $color-white;
    width: 100%;
    padding: 1rem 2rem;
    .pagination-container {
      margin: 0 auto;
      width: fit-content;
      &__pagination {
        margin-top: 0;
      }
    }
  }
}

</style>
